@tailwind base;

html {
  --color-primary-500: #48b1e9;
  --color-primary-400: #d63615;
  --color-primary-300: #f4a900;
  --color-primary-200: #b4c822;
  --color-primary-100: #29367a;
  --color-neutral-300: #b1b1b1;
  --color-neutral-400: #cecece;
  --color-neutral-white: white;
  --color-secondary-300: #cecece;
  --color-text-100: #444444;
  --color-text-200: #808080;
  --color-text-300: #cecece;
  --color-text-200-opacity-50: #c0c0c0;
  --color-text-400: white;
  --color-footer-contact: white;
  --color-footer-allrightsreserved: #e5e5e5;
  --primary-button-background: var(--color-primary-500);
  --primary-button-color: white;
  --primary-button-active-background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    #48b1e9;
  --primary-button-disabled-background: var(--color-neutral-300);
  --separated-icon-button-background: white;
  --separated-icon-button-color: var(--color-primary-100);
  --separated-icon-button-icon-background: var(--color-primary-500);
  --separated-icon-button-icon-color: white;
  --separated-icon-button-disabled-icon-background: var(--color-neutral-300);
  --separated-icon-button-disabled-color: var(--color-neutral-300);
  --button-shadow: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15));
  --input-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  --input-box-shadow-outline: 0px 0px 12px var(--color-primary-500);

  font-size: clamp(12px, 12px + (100vw - 800px) / (1920 - 800) * 4, 16px);
  font-family: 'DM Sans', sans-serif;
}

@media (max-width: 800px) {
  html {
    font-size: clamp(12px, 12px + (100vw - 375px) / (800 - 375) * 9, 21px);
  }
}

p {
  letter-spacing: 0.15px;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

svg {
  width: 100%;
  height: 100%;
}

.anchor {
  position: relative;
  top: -1rem;
}

.page {
  overflow: hidden;
}

.pageContent {
  --padding-x: clamp(40px, 40px + (100vw - 800px) / (1920 - 800) * 220, 260px);
  min-width: 375px;
  max-width: 1920px;
  padding: 0 var(--padding-x);
}
